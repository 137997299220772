import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import * as _ from 'lodash';

export function generateUUID(): string {
    var d = new Date().getTime();
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
}
  
export function getFormFieldValue(formControlName, formGroup, isBoolean?) {
    if(_.has(formGroup.controls, formControlName)) {
        if(isBoolean) {
            return (formGroup.controls[formControlName].value === '') ? false : formGroup.controls[formControlName].value; 
        }
        return formGroup.controls[formControlName].value || '';
    }
    return '';
}

export function handleFormValueChanges(event, formControlName, formGroup) {
    formGroup.controls[formControlName].setValue(event);
}

export function handelOnBlurChanges(event, formControlName, formGroup) {
    formGroup.get(formControlName).markAsDirty();
    formGroup.get(formControlName).markAsTouched();
}

export function checkFormFieldDisabled(formControlName, formGroup) {
    if(_.has(formGroup.controls, formControlName)) {
        return formGroup.controls[formControlName].disabled;
    }
    return false;
}

export function checkValidation(formControlName, validationName,  formGroup, isSubmitted) {
    const errorObj = formGroup.controls[formControlName].errors;
    const isTouched = formGroup.controls[formControlName].touched;
    return  !_.isEmpty(errorObj) 
            && _.has(errorObj, validationName) 
            && errorObj[validationName] 
            && (isTouched || isSubmitted); 
}

export function isLocalNetwork(hostname) {
    return (['localhost', '127.0.0.1', '', '::1'].includes(hostname))
}

export function base64ToCSV(input: string) {
  try {
      // Check if the input is a string
      if (typeof input !== 'string') {
          throw new Error('Input must be a string');
      }

      // Extract base64 data from data URI if present
      let base64String = input;
      if (input.startsWith('data:')) {
          base64String = input.split(',')[1];
      }

      // Convert base64 to binary string
      const binaryString = atob(base64String);

      // Convert binary string to text
      const textDecoder = new TextDecoder('utf-8');
      const uint8Array = new Uint8Array(binaryString.length);
      
      for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
      }

      return textDecoder.decode(uint8Array);
  } catch (error) {
      if (error.message === 'Invalid character') {
          throw new Error('Invalid base64 encoding');
      }
      throw error;
  }
}

export function downloadCSV(csvContent: string, filename = 'download.csv', document: Document) {
    // Create blob from CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create download link
    const link = document.createElement('a');
    
    // Create object URL
    const url = URL.createObjectURL(blob);
    
    // Set link properties
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    
    // Add to document, click, and remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Clean up the URL object
    URL.revokeObjectURL(url);
}